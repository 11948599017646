<template>
    <b-card class="p-4">
        <b-form @submit.prevent="saveRole">
            <b-form-group label="Description" label-class="fw-medium mb-1" label-for="description">
                <b-form-input
                    id="description"
                    type="text"
                    placeholder="Your description here"
                    v-model="description"
                    required
                ></b-form-input>
            </b-form-group>

            <b-form-group
                required
                v-slot="{ ariaDescribedby }"
                label="Modules & Permissions"
                label-class="fw-medium mb-3"
                label-for="roles"
            >
                <div v-for="item in authorizations" :key="item.id" class="mb-4">
                    <h4>{{ item.name }}</h4>
                    <div style="display: grid; grid-template-columns: repeat(3, 1fr); gap: 4px">
                        <b-form-checkbox
                            v-for="option in item.authorizations"
                            :key="option.id"
                            :value="option.id"
                            v-model="permissions"
                            name="screens"
                            :aria-describedby="ariaDescribedby"
                        >
                            {{ option.description }}
                        </b-form-checkbox>
                    </div>
                </div>
            </b-form-group>
            <div class="d-flex justify-content-end">
                <b-button :disabled="loadingRole" variant="primary" type="submit">
                    <b-spinner small v-if="loadingRole"></b-spinner>
                    <span v-else>Save Role</span>
                </b-button>
            </div>
        </b-form>
    </b-card>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    data() {
        return {
            role: {},
            permissions: [],
            description: '',
            loadingRole: false,
            currentRoute: window.location.pathname,
        };
    },
    computed: {
        ...mapGetters({
            authorizations: 'authorizations/getAuthorizations',
        }),
    },
    methods: {
        async getRoles() {
            const id = this.$route.params.id;
            if (!id) return;
            const roleData = await this.$store.dispatch('roles/get_role', id);
            this.role = roleData;
            this.permissions = roleData.authorizations.map((auth) => auth.id);
            this.description = roleData.description;
        },
        async getData() {
            await this.$store.dispatch('authorizations/get_allAuthorizations');
            await this.getRoles();
        },
        async saveRole() {
            this.loadingRole = true;
            if (this.currentRoute.includes('/update')) {
                await this.$store.dispatch('roles/update_role', {
                    id: this.$route.params.id,
                    auth_Ids: this.permissions,
                    description: this.description,
                });
            } else {
                await this.$store.dispatch('roles/add_role', {
                    description: this.description,
                    auth_Id: this.permissions,
                    isActive: true,
                });
            }
            this.loadingRole = false;
            this.$router.push('/security/roles/list');
        },
    },
    created() {
        this.getData();
    },
};
</script>
